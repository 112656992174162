import status from '@/vars/status'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'Reporting',
    data: () => ({
        reporttab: 0,
        status: status,
        reptable: {
            sortBy: ['visualts'],
            itemsPerPage: 15
        },
        repcols: [
            { text: 'Alerts', value: 'alerts', width: '2%' },
            { text: 'Program Name', value: 'title' },
            { text: 'PM', value: 'csrname' },
            { text: 'JSF', value: 'jsfnumber' },
            { text: 'Program Type', value: 'promotiontype' },
            { text: 'Job #', value: 'jobnumber' },
            { text: 'Art Due Date', value: 'artduets' },
            { text: 'Target Approval Date', value: 'calcapprovalshipts' },
            { text: 'Target Ship Date', value: 'shipts' },
            { text: 'Actual Ship Date', value: 'calcfmshipts' },
            { text: 'Visual Date', value: 'visualts' },
            { text: 'Notes', value: 'notes.length' },
            { text: 'PS', value: 'jsstatus' },
            { text: '', value: 'placeonhold' }
        ],
        repcolspo: [
            { text: 'Job Name', value: 'title' },
            { text: 'Tracking', value: 'jsfid' },
            { text: 'Visual Date', value: 'visualdate' },
            { text: 'Job Number', value: 'jobnumber' },
            { text: 'JSF', value: 'jsfnumber' },
            { text: 'Project Manager', value: 'csrname' },
            { text: 'Store Count', value: 'packoutstoreinfo' },
            { text: 'Quote Total', value: 'quoteinfo.totalProjectPrice', width: '10%' }
        ],
        repcolsref: [],
        jsfreptypes: [
            { text: 'Numbers', value: 'numbers' },
            { text: 'Sales', value: 'sales' },
            { text: 'Freight', value: 'freight' },
            { text: 'Parts Ordered', value: 'parts' },
            { text: 'By Buyer', value: 'buyer' },
            { text: 'By Brand', value: 'brand' },
            { text: 'Create to Ship', value: 'ctoship' },
            { text: 'Create to Visual', value: 'ctovisual' }
        ],
        statuslistadmin: [
            { text: 'Pre-production', value: '0', path: 'preproduction' },
            { text: 'Active', value: '1', path: 'active' }
            /* --- future use { text: 'FOS', value: 'fos', path: 'fos' }, --- */
        ],
        statuslistreporter: [
            { text: 'Pack-out', value: '2', path: 'packout' }
        ],
        selectedstatus: '',
        selectedjsfrep: '',
        menu0: false,
        menu1: false,
        originalrepfromdate: moment().startOf('year').format('YYYY-MM-DD'),
        originalreptodate: moment().format('YYYY-MM-DD'),
        repfromdate: moment().subtract(12, 'month').format('YYYY-MM-DD'),
        reptodate: moment().format('YYYY-MM-DD'),
        yaxisoptions: [
            { name: 'numbers', title: 'Number of JSFs' },
            { name: 'sales', title: 'Sales' },
            { name: 'freight', title: 'Freight' },
            { name: 'parts', title: 'Parts' },
            { name: 'buyer', title: 'Buyer JSFs' },
            { name: 'brand', title: 'Brand JSFs' },
            { name: 'ctoship', title: 'Time to Ship' },
            { name: 'ctovisual', title: 'Time to Visual' }
        ],
        yaxistitle: '',
        search: '',
        recsdisplayed: 0,
        queryinfo: [
            'No PDF, No FM billing status or FM billing status of NOT BILLED',
            'Have PDF, No FM status or FM status of LIVE, FM billing status of NOT BILLED',
            'Have PDF, FM status = any value, FM billing status = any value, FM ship date not empty'
        ],
        expanded: [],
        whatexpand: '',
        noteedit: false,
        notebutton: 'Save',
        noteedittitle: '',
        noteid: '',
        notejsf: '',
        addthisnote: '',
        selectedautonote: '',
        auxfunction: '',
        autonote: [
            { text: 'Art Received', value: 'Art Received' },
            { text: 'Waiting for Art', value: 'Waiting for Art' },
            { text: 'Mock Up In-Process', value: 'Mock Up In-Process' },
            { text: 'Mock Up Sent', value: 'Mock Up Sent' },
            { text: 'Dillard\'s Approved Art', value: 'Dillard\'s Approved Art' },
            { text: 'Brand Approved Art', value: 'Brand Approved Art' },
            { text: 'Approved for Production', value: 'Approved for Production' },
            { text: 'Dates will Change', value: 'Dates will Change' },
            { text: 'Project Canceled', value: 'Project Canceled' },
            { text: 'PROGRAM ON HOLD', value: 'PROGRAM ON HOLD' }
        ],
        packoutdate: false,
        selectedpackoutdate: '',
        packoutdatedisplay: '[none selected]',
        getquoteinfo: false
    }),
    async mounted() {
        if (this.$route.params.repname) {
            await this.$nextTick(() => {
                switch (this.$route.params.repname) {
                    case 'preproduction':
                        this.grabreport(0)
                        this.$nextTick(() => this.selectedstatus = '0')
                        break
                    case 'active':
                        this.grabreport(1)
                        this.$nextTick(() => this.selectedstatus = '1')
                        break
                    case 'packout':
                        this.grabreport(2)
                        this.$nextTick(() => this.selectedstatus = '2')
                        break
                    /* --- future use
                case 'fos':
                    this.grabreport('fos')
                    this.$nextTick(() => this.selectedstatus = 'fos')
                    break
                    --- */
                }

                this.$store.dispatch('Reporting/fetchDeptList', null, { root: true })
            })
        }

        let reportinfo = {
            repName: 'Area',
            fromDate: '2021-10-01',
            toDate: '2021-12-31',
            whereCondition: { operator: 'like', value: 'holiday' }
        }

        this.$store.dispatch('Reporting/fetchReport', reportinfo, { root: true })

        this.repcolsref = [
            /* --- order here matches the value of statuslist --- */
            this.repcols,
            this.repcols,
            this.repcolspo
        ]

        this.showholdrecs = false
        let workshowhold = localStorage.getItem('showholdrecs')

        if (workshowhold !== 'null') {
            if (workshowhold === 'true') {
                this.showholdrecs = true
            }
        }

    },
    computed: {
        ...mapGetters('Sgs', ['getLoggedIn', 'getUser']),
        ...mapState('Sgs', ['user']),
        depsforreps: { get() { return this.$store.getters['Reporting/getDepsForReps'] }, set(val) { this.$store.commit('Reporting/SET_DEPSFORREPS', val) } },
        depsforrepsselected: { get() { return this.$store.getters['Reporting/getDepsForRepsSel'] }, set(val) { this.$store.commit('Reporting/SET_DEPSFORREPSSEL', val) } },
        prodrecs: { get() { return this.$store.getters['Reporting/getProdRecs'] }, set(val) { this.$store.commit('Reporting/SET_PRODRECS', val) } },
        packoutdates: { get() { return this.$store.getters['Reporting/getPackoutDates'] } },
        holdcount: { get() { return this.$store.getters['Reporting/getHoldCount'] } },
        showholdrecs: { get() { return this.$store.getters['Reporting/getShowHoldRecs'] }, set(val) { this.$store.commit('Reporting/SET_SHOWHOLDRECS', val) } },
        statuslist: {
            get() {
                let worklist = []

                if (this.user.showreportadmin) {
                    for (let sidx = 0; sidx < this.statuslistadmin.length; sidx++) {
                        worklist.push(this.statuslistadmin[sidx])
                    }

                    for (let sidx = 0; sidx < this.statuslistreporter.length; sidx++) {
                        worklist.push(this.statuslistreporter[sidx])
                    }
                }

                if (this.user.showreportclient) {
                    for (let sidx = 0; sidx < this.statuslistreporter.length; sidx++) {
                        worklist.push(this.statuslistreporter[sidx])
                    }
                }

                return worklist
            }
        }
    },
    methods: {
        pushreport() {
            this.$store.dispatch('Reporting/fetchNumberRep', null, { root: true })
        },
        displayreport() {
            this.yaxistitle = this.yaxisoptions.find(o => o.name === this.selectedjsfrep).title

            if (!this.yaxistitle) {
                this.yaxistitle = 'Value'
            }
        },
        saveprevdate(whichone) {
            const whichdate = 'rep' + whichone + 'date',
                oriwhichdate = 'originalrep' + whichone + 'date'

            this[oriwhichdate] = this[whichdate]
            //this.$store.dispatch('Reporting/fetchGraph', { fromdate: this.repfromdate, todate: this.reptodate }, { root: true })
        },
        grabreport: function (e) {
            if (e == '2') {
                /* --- 200 gets dates for select list --- */
                this.grabreportgo(200)
                this.packoutdate = true
                this.$nextTick(() => this.selectedstatus = '2')
            } else {
                /* --- 100 = ship date selected for packout, need to reset --- */
                if (e === 100) {
                    this.packoutdate = false
                    this.getquoteinfo = true

                    if (moment.unix(this.selectedpackoutdate).utcOffset(-5).format('MM/DD/YYYY') !== 'Invalid date') {
                        this.packoutdatedisplay = moment.unix(this.selectedpackoutdate).utcOffset(-5).format('MM/DD/YYYY')
                    } else {
                        this.packoutdatedisplay = this.selectedpackoutdate
                    }
                    e = '2'
                }

                this.grabreportgo(e)
            }
        },
        grabreportgo: function (e) {
            /* ---
              there is a duplicate of this in /home/ubuntu/scripts/reports.js on the web server
              if significant updates are made here, make them there too!!
            --- */

            this.$store.dispatch('Reporting/fetchProd', {
                op: 'getreport',
                reptype: e,
                packoutdate: this.selectedpackoutdate,
                getquoteinfo: this.getquoteinfo
            }, { root: true })
                .then(() => { this.getquoteinfo = false })
                .catch(err => {
                    console.log('FETCH PROD ERR')
                    console.log(err)
                })
        },
        getTotalItems(e) {
            this.recsdisplayed = e.itemsLength
        },
        filterit(what) {
            this.search = what
        },
        showMore(item, what) {
            this.whatexpand = ''

            if (this.expanded.length && this.expanded[0].jsfid === item.jsfid) {
                this.expanded = []
            } else {
                this.expanded = []
                this.expanded.push(item)
            }

            switch (what) {
                case 'note':
                    this.whatexpand = 'note'
                    break
            }

        },
        shownoteedit(item) {
            this.noteedit = true
            this.notebutton = 'Save'
            this.notejsf = item.jsfnumber
            this.noteedittitle = item.title
            this.notecurrent = this.prodrecs.indexOf(item)
            this.$nextTick(() => this.$refs.notebox.focus())
        },
        shownoteeditmod(item, noterow) {
            this.noteedit = true
            this.notebutton = 'Update'
            this.notejsf = item.jsfnumber
            this.noteid = noterow.id
            this.noteedittitle = item.title
            this.notecurrent = this.prodrecs.indexOf(item)
            this.$nextTick(() => this.$refs.notebox.focus())
            this.$nextTick(() => this.addthisnote = noterow.message)
        },
        closenoteedit() {
            this.noteedit = false
            this.packoutdate = false
            this.getquoteinfo = false
            this.packoutdatedisplay = '[none selected]'
            this.addthisnote = ''
        },
        addnote: function () {
            this.noteedit = false

            let dowhat = 'addnote',
                sendid = this.notejsf,
                postinfo = {}

            switch (this.selectedautonote) {
                case 'Art Received':
                    this.auxfunction = 'stampartreceived'
                    break
                case 'Mock Up Sent':
                    this.auxfunction = 'stampmockupsent'
                    break
                default:
                    this.auxfunction = ''
            }

            switch (this.notebutton) {
                case 'Save':
                    dowhat = 'addnote'
                    sendid = this.notejsf
                    break
                case 'Update':
                    dowhat = 'modnote'
                    sendid = this.noteid
                    break
            }

            postinfo.op = dowhat
            postinfo.sendid = sendid
            postinfo.user = this.$store.getters['Sgs/getUser']
            postinfo.note = this.addthisnote
            postinfo.type = 'note'
            postinfo.jsfnumber = this.notejsf
            postinfo.aux = this.auxfunction


            this.$store.dispatch('Reporting/modifyNote', postinfo, { root: true })
                .then(() => {
                    this.grabreport(this.selectedstatus)
                })
                .catch(function (err) {
                    console.log('DISPATCH MOD NOTE ERROR HERE')
                    console.log(err)
                })
        },
        addautonote: function (e) {
            this.addthisnote = e
        },
        setjsfhold: function (item) {
            //console.log('SETTING HOLD')
            //console.log(item)
            item.metakey = 'holdstatus'

            if (item.holdcolor === 'red') {
                item.metavalue = true
            } else {
                item.metavalue = false
            }

            this.$store.dispatch('Jsf/modJsfMeta', item, { root: true })
            this.refresh()
        },
        setrowclass(item) {
            return item.attention
        },
        refresh() {
            this.grabreport(this.selectedstatus)
        },
        getDownload() {
            this.$store.dispatch('Sgs/sendSheet', { op: this.selectedstatus, recs: this.prodrecs, packoutdatedisplay: this.packoutdatedisplay })
        },
        pushRepPath(e) {
            let repname = this.statuslist.find(o => o.value === e)
            this.$router.push({ path: '/reports/' + repname.path })
            this.grabreport(e)
        },
        showHold(showit) {
            if (showit) {
                this.showholdrecs = true
            } else {
                this.showholdrecs = false
            }

            localStorage.setItem('showholdrecs', this.showholdrecs)

            this.refresh()
        }
    }
}