<template>
    <v-container fluid>
        <!-- v-row class="text-center">
            <v-flex xs12>
                <h1>Reporting</h1>
            </v-flex>
        </v-row -->
        <ReportingForm />
    </v-container>
</template>

<script>
import ReportingForm from '@/components/ReportingForm.vue'

export default {
    name: 'Reporting',
    components: {
        ReportingForm,
    },
}
</script>